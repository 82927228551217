import request from './request'

const api = {
	// 列表
	lists: '/User/index',
	// 新增
	add: '/User/create',
	// 删除
	dele: '/User/delete',
	// 审核
	checks: '/User/check_status',
	// 获取用户上级用户
	getParentListUid: '/User/getParentListUid',
	// 修改上级
	updateParent: '/User/updateParent',
	// 修改用户信息
	update: '/User/update',
	// 用户详情
	read: '/User/read',
}

export {
	request,
	api
}
