<template>
	<div>
		<el-dialog draggable top="5vh" :destroy-on-close="true" @close="close" v-model="drawer" title="编辑">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="头像">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.avatar" class="dialog-img" :src="ruleForm.avatar" />
							<img v-else class="dialog-img" src="@/assets/img/tx.png" />
							<p v-if="ruleForm.avatar" class="dele" @click="dele">删除</p>
							<el-upload :headers="Headers" :action="ActionUrl" :on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="姓名">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<span class="xx">*</span>
						<el-select v-model="ruleForm.sex" placeholder="请选择性别">
							<el-option label="男" :value="0"></el-option>
							<el-option label="女" :value="1"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="drawer = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/trial";
	export default {
		data() {
			return {
				Headers: {
					authorization: '',
					appid: '',
					nonce: '',
					timestamp: '',
					sign: ''
				},
				ActionUrl: '',
				ruleForm: {
					name: '',
					avatar: '',
					phone: '',
					sex: ''
				},
				drawer: false,
				id: ''
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'
		},
		methods: {
			// 提交
			SubmitForm() {
				if (this.ruleForm.name == '') {
					ElMessage({
						message: '请输入姓名',
						type: 'warning',
					})
				} else if (this.ruleForm.phone == '') {
					ElMessage({
						message: '请输入手机号码',
						type: 'warning',
					})
				} else if (this.ruleForm.sex === '') {
					ElMessage({
						message: '请选择性别',
						type: 'warning',
					})
				} else {
					this.$http.post(api.update, this.ruleForm)
						.then((res) => {
							if (res.code == 1) {
								ElMessage({
									type: 'success',
									message: '修改成功',
								})
								this.drawer = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						})
				}

			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.avatar = res.data
			},
			// 关闭
			close() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.read, {
						id: this.id
					})
					.then((res) => {
						if (res.code == 1) {
							this.ruleForm = res.data
						}
					})
			},
			// 显示
			show(id) {
				this.id = id
				this.drawer = true
				this.getinfo()
			},
		}
	}
</script>

<style scoped>

</style>
