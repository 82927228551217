<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-permission="'delete'" v-if="$store.state.userinfo.roles==2">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="clearable('name')" type="text" clearable v-model="form.name" placeholder="请输入姓名">
					</el-input>
				</li>
				<li>
					<el-select clearable @clear="clearable('status')" v-model="form.check_status" placeholder="状态">
						<el-option label="待审核" :value="0"></el-option>
						<el-option label="审核通过" :value="1"></el-option>
						<el-option label="审核未通过" :value="2"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#5473E8" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="date" label="头像">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.avatar">
							<img src="../../assets/img/tx.png" />
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="name" label="我的上级">
					<template #default="scope">
						{{scope.row.parent!=null?scope.row.parent.name:'无'}}
					</template>
				</el-table-column>
				<el-table-column property="name" label="姓名" />
				<el-table-column property="phone" label="手机号码" />
				
				<el-table-column v-if="$store.state.userinfo.roles==1" property="department" label="地区/部门">
					
					<template #default="scope">
						{{scope.row.county!=null?scope.row.county.name:''}}
						<span v-if="scope.row.town.length>0"
							v-for="(item,indexs) in scope.row.town">{{item.name}}/</span>
						{{scope.row.village!=null?scope.row.village.name:''}}
					</template>
				</el-table-column>
				
				<el-table-column v-if="$store.state.userinfo.type==1&&$store.state.userinfo.roles==2"
					property="department" label="部门" />
				
				<el-table-column v-if="$store.state.userinfo.type==0&&$store.state.userinfo.roles==2" label="地区">
					<template #default="scope">
						{{scope.row.county!=null?scope.row.county.name:''}}
						<span v-if="scope.row.town.length>0"
							v-for="(item,indexs) in scope.row.town">{{item.name}}/</span>
						{{scope.row.village!=null?scope.row.village.name:''}}
					</template>
				</el-table-column>
				
				<el-table-column property="name" label="职位">
					<template #default="scope">
						<p v-if="scope.row.position_type==0" type="success">督查室</p>
						<p v-if="scope.row.position_type==1" type="warning">县纪委</p>
						<p v-if="scope.row.position_type==2" type="danger">区域网格总长</p>
						<p v-if="scope.row.position_type==3" type="success">区域网格长</p>
						<p v-if="scope.row.position_type==4" type="warning">网格长</p>
						<p v-if="scope.row.position_type==5" type="danger">网格员</p>
					</template>
				</el-table-column>
				<el-table-column property="name" label="状态">
					<template #default="scope">
						<el-tag v-if="scope.row.check_status==1" type="success">已通过</el-tag>
						<el-tag v-if="scope.row.check_status==0" type="warning">待审核</el-tag>
						<el-tag v-if="scope.row.check_status==2" type="danger">未通过</el-tag>
					</template>
				</el-table-column>
				<el-table-column sortable property="create_time" width="120" label="注册时间" />
				<el-table-column property="address" label="操作" width="250">
					<template #default="scope">
						<el-button v-permission="'adopt'" class="btn-b" size="mini" @click="check(scope.row.id,1)"
							v-if="scope.row.check_status==0">通过
						</el-button>
						<el-button v-permission="'refuse'" class="btn-b" size="mini" @click="check(scope.row.id,2)"
							v-if="scope.row.check_status==0">拒绝
						</el-button>
						<el-button v-if="scope.row.is_delete" v-permission="'delete'" class="btn-b"
							@click="dele(scope.row.id)" size="mini">删除</el-button>
						<el-button v-if="scope.row.check_status==1" class="btn-b" @click="edits(scope.row.id)"
							size="mini">编辑</el-button>
						<el-button v-if="scope.row.is_parent_id&&scope.row.check_status==1" class="btn-b"
							@click="superior(scope.row.id)" size="mini">修改上级</el-button>
						<el-button v-if="scope.row.is_position_type&&scope.row.check_status==1" class="btn-b"
							@click="openapp(scope.row.id)" size="mini">修改职务</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background :current-page="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 修改上级 -->
		<superior ref="superior" @SubmitForm="getList"></superior>
		<!-- 编辑 -->
		<edits ref='edits' @SubmitForm="getList"></edits>
		<!-- 修改职位 -->
		<addAPP ref="addAPP" @SubmitForm="getList"></addAPP>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/trial";
	import superior from './superior.vue'
	import edits from './edits.vue'
	import addAPP from '../users/addAPP.vue'
	export default {
		name: 'users',
		components: {
			superior,
			edits,
			addAPP
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				tableData: [],
				total: 0,
				tableloading: false,
				form: {
					page: 1,
					limit: 15,
					check_status: "",
					name: ''
				},
				outerVisible: false,
				idlist: [],
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 修改职位
			openapp(id) {
				this.$refs.addAPP.show(id)
			},
			// 编辑
			edits(id) {
				this.$refs.edits.show(id)
			},
			// 修改上级
			superior(id) {
				this.$refs.superior.show(id)
			},
			// 单独清空
			clearable(val) {
				this.form.val = ""
				this.form.page = 1
				this.getList()
			},
			// 审核
			check(id, val) {
				ElMessageBox.confirm(
						'是否确定审核?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.checks, {
								id: id,
								check_status: val
							})
							.then((res) => {
								if (res.code == 1) {
									ElMessage({
										type: 'success',
										message: '提交成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 1) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 1) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			},
			// 清空
			reset() {
				this.form.name = ''
				this.form.check_status = ""
				this.form.page = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 确定添加/编辑
			SubmitForm() {
				this.$refs.Form.SubmitForm()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 1) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			}
		}
	}
</script>

<style scoped>
	.btn-b {
		margin-bottom: 10px;
	}
</style>
