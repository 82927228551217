<template>
	<div>
		<el-dialog draggable top="5vh" :destroy-on-close="true" @close="close" v-model="drawer" title="修改上级">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="选择上级">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.parent_id" @change="changetype" placeholder="请选择选择上级">
							<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="drawer = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/trial";
	export default {
		data() {
			return {
				drawer: false,
				ruleForm: {
					parent_id: '',
					id: ''
				},
				id: '',
				options: []
			}
		},
		mounted() {

		},
		methods: {
			// 获取上级
			getList() {
				this.$http.post(api.getParentListUid, {
						id: this.id
					})
					.then((res) => {
						if (res.code == 1) {
							this.options = res.data
						}
					})
			},
			// 关闭
			close() {
				this.ruleForm.parent_id = ''
			},
			// 显示
			show(id) {
				this.id = id
				this.ruleForm.id = id
				this.drawer = true
				this.getList()
			},
			// 提交
			SubmitForm() {
				if (this.ruleForm.parent_id == '') {
					ElMessage({
						message: '请选择选择上级',
						type: 'warning',
					})
				} else {
					this.$http.post(api.updateParent, this.ruleForm)
						.then((res) => {
							if (res.code == 1) {
								ElMessage({
									type: 'success',
									message: '修改成功',
								})
								this.drawer = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						})
				}

			}
		}
	}
</script>

<style scoped>
</style>
